import React, { useState } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { css, Global } from '@emotion/react'
import styled from '@emotion/styled'
import { mobile, desktop } from '~styles/global'
import { useCart } from '~context/siteContext'
import resolveLink from '~utils/resolveLink'
import Image from '~components/Image'
import Incrementer from '~components/Incrementer'
import prepareCheckout from '~utils/prepareCheckout'
import Seo from '~components/Seo'
import Section from '~components/Section'
import Button from '~components/Button'
import { BdrCross } from '~components/Svg'

const Item = ({ variant }) => {

	const { updateQty, removeItem } = useCart()
	return(
		<LineItem className="h5">
			<Title to={variant.link}>{variant.title}</Title>
			<Price>${variant?.price?.toFixed(2)}</Price>
			<Remove onClick={() => removeItem(variant.id)}><Cross/></Remove>
		</LineItem>
	)
}

Item.propTypes = {
	variant: PropTypes.object,
}

const Cart = ({ open, className }) => {

	const { cartTotal, cart, closeCart } = useCart()
	const [loading, setLoading] = useState(false)

	const loadCheckout = () => {
		setLoading(true)
		prepareCheckout(cart)
	}

	return(
		<>
			<Seo title='Cart'	/>
			<Wrap 
				className={className}
			>
				<Container>
					{cart.length ? 
						cart.map((variant, i) => (
							<Item variant={variant} key={variant.id} index={i}/>
						))
						:
						<>
							<Empty>
            		Your Cart is Empty
							</Empty> 
							<ShopContainer>
								<InlineShopButton 
									bgColor='dteal'
									link={{
										linkType: 'internal',
										document: {
											slug: {
												current: 'panda-learning-hub'
											}
										}
									}}
								>
									Back to courses
								</InlineShopButton>
							</ShopContainer>
						</>
					}
				</Container>
				{cart.length > 0 &&
					<CheckoutContainer>
						<TotalPrice>
							<Total>Total</Total>	
							${cartTotal().toFixed(2)}
						</TotalPrice>
						<CheckoutButton 
							onClick={() => loadCheckout(cart)}
							bgColor='dteal'
							icon='Rarr'
						>
							{loading ? 'Loading...' : 'Check out'}
						</CheckoutButton>
						<ShopButton 
							bgColor='dteal'
							link={{
								linkType: 'internal',
								document: {
									slug: {
										current: 'panda-learning-hub'
									}
								}
							}}
						>
							Back to courses
						</ShopButton>
					</CheckoutContainer>
				}

			</Wrap>
		</>
	)
}

const Wrap = styled(Section)`
	padding: 100px 0;
  ${mobile}{
    padding: 100px 0;
  }
`
const Container = styled.div`
	grid-column: span 12;
	margin-bottom: var(--m);
`
const Empty = styled.h5`
	text-align: center;
	margin-bottom: var(--s);
`
const LineItem = styled.div`
	padding: var(--xs);
	grid-column: span 12;
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-column-gap: 30px;
	align-items: center;
	:nth-of-type(2n){
		background-color: var(--bteal40);
	}
	${mobile}{
		grid-column-gap: 14px;
		padding: var(--xs) var(--xxs); 
	}
`
const Title = styled(Link)`
	grid-column: span 7;
	${desktop}{
		grid-column: span 10;
	}
	${mobile}{
		grid-column: 1/11;
		margin-bottom: var(--s);
		grid-row: 1;
	}
`
const Price = styled.div`
	grid-column: span 1;
	${mobile}{
		grid-column: 8/13;
		text-align: right;
	}
`
const Remove = styled.button`
	grid-column: span 1;
	justify-self: end;
	height: 34px;
	width: 34px;
	${mobile}{
		grid-row: 1;
		grid-column: 11/13;
		justify-self: end;
		align-self: start;
	}
`
const Cross = styled(BdrCross)`
`
const CheckoutContainer = styled.div`
	justify-self: end;
	grid-column: span 12;
	${mobile}{
		justify-self: initial;
	}
`
const TotalPrice = styled.h5`
	text-align: right;
	margin-bottom: var(--m);
	${mobile}{
		display: flex;
		justify-content: space-between;
	}
`
const CheckoutButton = styled(Button)`
	margin-bottom: var(--xs);
`
const ShopButton = styled(Button)`
	display: flex;
`
const ShopContainer = styled.div`
	display: flex;
	justify-content: center;
`
const InlineShopButton = styled(Button)`
	margin: 0 auto;
`
const Total = styled.div`
	margin-bottom: var(--xxs);
`

Cart.propTypes = {
	open: PropTypes.bool,
	className: PropTypes.string,
}

export default Cart
